<template>
  <div class="share">
    <div class="share__title">Comparte este artículo</div>
    <!-- Listado de redes para share -->
    <div class="share__items">
      <a
        :href="'whatsapp://send?text=Visto en HelloBB ' + currentUrl()"
        class="share__item share__item--whatsapp"
        aria-label="Compartir en Whatsapp"
      >
        <img
          src="../../../assets/img/ico/ico-share-whatsapp.svg"
          alt="Whatsapp"
          loading="lazy"
        />
      </a>
      <a
        :href="'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl()"
        class="share__item share__item--facebook"
        aria-label="Compartir en Facebook"
      >
        <img
          src="../../../assets/img/ico/ico-share-facebook.svg"
          alt="Facebook"
          loading="lazy"
        />
      </a>
      <a
        :href="
          'http://pinterest.com/pin/create/button/?url=' +
          post.url +
          '&media=' +
          currentUrl() +
          '&description=' +
          post.excerpt
        "
        count-layout="horizontal"
        class="share__item share__item--pinterest pin-it-button"
        aria-label="Compartir en Pinterest"
      >
        <img
          src="../../../assets/img/ico/ico-share-pinterest.svg"
          alt="Pinterest"
          loading="lazy"
        />
      </a>

      <a
        :href="
          'https://twitter.com/intent/tweet?text=Visto en HelloBB&?url=' + currentUrl()
        "
        class="share__item share__item--twitter"
        aria-label="Compartir en Twitter"
      >
        <img
          src="../../../assets/img/ico/ico-share-twitter.svg"
          alt="Twitter"
          loading="lazy"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Share",
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    currentUrl() {
      return (
        process.env.APP_URL.substring(0, process.env.APP_URL.length - 1) +
        this.$route.path
      );
    },
  },
};
</script>
