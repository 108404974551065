<template>
  <div class="h-sticky-blog">
    <header class="header-blog">
      <div class="bb-container">
        <div class="header-blog__container">
          <a :href="'/blog' + appQueryParam">
            <div class="header-blog__logo">
              <img
                src="../../assets/img/logos/logo-hellobb-blog-light.svg"
                alt="Blog HelloBB"
              />
            </div>
          </a>

          <div class="header-blog__nav">
            <nav class="nav-blog">
              <a :href="'/blog/categorias/listas-de-ejemplo' + appQueryParam">
                <div class="nav-blog__item">
                  <div class="nav-blog__parent" alt="Listas de ejemplo">
                    Listas de ejemplo
                  </div>
                </div>
              </a>

              <div class="nav-blog__item">
                <span
                  v-on:mouseover="isOpenMaternity = true"
                  v-on:mouseleave="isOpenMaternity = false"
                >
                  <a :href="'/blog/categorias/maternidad' + appQueryParam">
                    <div
                      class="nav-blog__parent nav-blog__parent--dropdown"
                      :class="
                        isOpenMaternity ? 'nav-blog__parent--dropdown-open' : ''
                      "
                      alt="Maternidad"
                    >
                      Maternidad
                    </div>
                  </a>

                  <ul
                    class="nav-blog__dropdown"
                    :class="{ isOpen: isOpenMaternity }"
                  >
                    <a
                      :href="'/blog/categorias/' + category.slug + appQueryParam"
                      v-for="(category, index) in maternityCategories"
                      :key="index"
                    >
                      <li class="nav-blog__dropdown-item">
                        <div
                          class="nav-blog__dropdown-link"
                          :alt="category.slug"
                        >
                          {{ category.name }}
                        </div>
                      </li>
                    </a>
                  </ul>
                </span>
              </div>
              <div class="nav-blog__item">
                <span
                  v-on:mouseover="isOpenProducts = true"
                  v-on:mouseleave="isOpenProducts = false"
                >
                  <a :href="'/blog/categorias/productos' + appQueryParam">
                    <div
                      class="nav-blog__parent nav-blog__parent--dropdown"
                      :class="
                        isOpenProducts ? 'nav-blog__parent--dropdown-open' : ''
                      "
                      alt="Productos"
                    >
                      Productos
                    </div>
                  </a>
                  <ul
                    class="nav-blog__dropdown"
                    :class="{ isOpen: isOpenProducts }"
                  >
                    <a
                      :href="'/blog/categorias/' + category.slug + appQueryParam"
                      v-for="(category, index) in productsCategories"
                      :key="index"
                    >
                      <li class="nav-blog__dropdown-item">
                        <div
                          :alt="category.slug"
                          class="nav-blog__dropdown-link"
                        >
                          {{ category.name }}
                        </div>
                      </li>
                    </a>
                  </ul>
                </span>
              </div>
            </nav>
          </div>

          <div class="header-blog__category-m">
            <button class="button button--sm button--ico" @click="openModal()">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.66634 9.16666H1.99967C1.82286 9.16666 1.65329 9.2369 1.52827 9.36192C1.40325 9.48695 1.33301 9.65652 1.33301 9.83333V14.5C1.33301 14.6768 1.40325 14.8464 1.52827 14.9714C1.65329 15.0964 1.82286 15.1667 1.99967 15.1667H6.66634C6.84315 15.1667 7.01272 15.0964 7.13775 14.9714C7.26277 14.8464 7.33301 14.6768 7.33301 14.5V9.83333C7.33301 9.65652 7.26277 9.48695 7.13775 9.36192C7.01272 9.2369 6.84315 9.16666 6.66634 9.16666ZM5.99967 13.8333H2.66634V10.5H5.99967V13.8333ZM13.9997 1.83333H9.33301C9.1562 1.83333 8.98663 1.90357 8.8616 2.02859C8.73658 2.15361 8.66634 2.32318 8.66634 2.49999V7.16666C8.66634 7.34347 8.73658 7.51304 8.8616 7.63807C8.98663 7.76309 9.1562 7.83333 9.33301 7.83333H13.9997C14.1765 7.83333 14.3461 7.76309 14.4711 7.63807C14.5961 7.51304 14.6663 7.34347 14.6663 7.16666V2.49999C14.6663 2.32318 14.5961 2.15361 14.4711 2.02859C14.3461 1.90357 14.1765 1.83333 13.9997 1.83333ZM13.333 6.5H9.99967V3.16666H13.333V6.5ZM13.9997 9.16666H9.33301C9.1562 9.16666 8.98663 9.2369 8.8616 9.36192C8.73658 9.48695 8.66634 9.65652 8.66634 9.83333V14.5C8.66634 14.6768 8.73658 14.8464 8.8616 14.9714C8.98663 15.0964 9.1562 15.1667 9.33301 15.1667H13.9997C14.1765 15.1667 14.3461 15.0964 14.4711 14.9714C14.5961 14.8464 14.6663 14.6768 14.6663 14.5V9.83333C14.6663 9.65652 14.5961 9.48695 14.4711 9.36192C14.3461 9.2369 14.1765 9.16666 13.9997 9.16666ZM13.333 13.8333H9.99967V10.5H13.333V13.8333ZM6.66634 1.83333H1.99967C1.82286 1.83333 1.65329 1.90357 1.52827 2.02859C1.40325 2.15361 1.33301 2.32318 1.33301 2.49999V7.16666C1.33301 7.34347 1.40325 7.51304 1.52827 7.63807C1.65329 7.76309 1.82286 7.83333 1.99967 7.83333H6.66634C6.84315 7.83333 7.01272 7.76309 7.13775 7.63807C7.26277 7.51304 7.33301 7.34347 7.33301 7.16666V2.49999C7.33301 2.32318 7.26277 2.15361 7.13775 2.02859C7.01272 1.90357 6.84315 1.83333 6.66634 1.83333ZM5.99967 6.5H2.66634V3.16666H5.99967V6.5Z"
                  fill="#ffffff"
                />
              </svg>

              Categorías
            </button>
          </div>

          <div class="header-blog__search">
            <div class="gcse-search"></div>
          </div>

          <div class="header-blog__search-m">
            <button class="button-search-m" @click="searchBar = !searchBar">
              <img
                src="../../assets/img/ico/ico-search-light.svg"
                alt="Buscar"
              />
            </button>
          </div>
        </div>

        <!--<div
          class="header-blog__search-bar"
          :class="searchBar ? 'header-blog__search-bar--active' : ''"
        >
          <div class="gcse-search"></div>
        </div>-->
      </div>
    </header>

    <transition name="bbFade" @after-enter="showCategoriesContent = true">
      <div class="modal" v-if="showCategories" @click="closeModal()">
        <transition name="bbUp" @after-leave="showCategories = false">
          <div v-if="showCategoriesContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back"></div>
                <div class="modal__close">
                  <img
                    src="../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="closeModal()"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="mtax">
                  <h2 class="mtax__title">Categorías</h2>
                  <div class="mtax__content">
                    <div class="dd-tax">
                      <a :href="'/blog' + appQueryParam">
                        <div class="dd-tax__item">
                          <div class="dd-tax__header">
                            <div class="dd-tax__header-left">
                              <div class="dd-tax__ico">
                                <img
                                  :src="IcoTodos"
                                  alt="Todo el contenido"
                                  loading="lazy"
                                />
                              </div>
                              <div class="dd-tax__title">Todo el contenido</div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a :href="'/blog/categorias/listas-de-ejemplo' + appQueryParam">
                        <div class="dd-tax__item">
                          <div class="dd-tax__header">
                            <div class="dd-tax__header-left">
                              <div class="dd-tax__ico">
                                <img
                                  :src="IcoListas"
                                  alt="Todo el contenido"
                                  loading="lazy"
                                />
                              </div>
                              <div class="dd-tax__title">Listas de ejemplo</div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <div class="dd-tax__item">
                        <div
                          class="dd-tax__header"
                          @click="
                            isOpenMaternity = !isOpenMaternity;
                            isOpenProducts = false;
                          "
                        >
                          <div class="dd-tax__header-left">
                            <div class="dd-tax__ico">
                              <img :src="IcoMaternidad" alt="Maternidad" />
                            </div>
                            <div class="dd-tax__title">Maternidad</div>
                          </div>
                          <div class="dd-tax__arrow">
                            <img
                              v-if="isOpenMaternity"
                              src="../../assets/img/ico/ico-chevron-down.svg"
                              alt="Open / Close"
                              loading="lazy"
                            />
                            <img
                              v-else
                              src="../../assets/img/ico/ico-chevron-up.svg"
                              alt="Open / Close"
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <a
                          :href="'/blog/categorias/' + category.slug + appQueryParam"
                          v-for="(category, index) in maternityCategories"
                          :key="index"
                          v-if="isOpenMaternity"
                        >
                          <div class="dd-tax__nav">
                            <div class="dd-tax__nav-link">
                              {{ category.name }}
                            </div>
                          </div>
                        </a>
                      </div>

                      <div class="dd-tax__item">
                        <div
                          class="dd-tax__header"
                          @click="
                            isOpenProducts = !isOpenProducts;
                            isOpenMaternity = false;
                          "
                        >
                          <div class="dd-tax__header-left">
                            <div class="dd-tax__ico">
                              <img :src="IcoProductos" alt="Productos" />
                            </div>
                            <div class="dd-tax__title">Productos</div>
                          </div>
                          <div class="dd-tax__arrow">
                            <img
                              v-if="isOpenProducts"
                              src="../../assets/img/ico/ico-chevron-down.svg"
                              alt="Open / Close"
                              loading="lazy"
                            />
                            <img
                              v-else
                              src="../../assets/img/ico/ico-chevron-up.svg"
                              alt="Open / Close"
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <a
                          :href="'/blog/categorias/' + category.slug + appQueryParam"
                          v-for="(category, index) in productsCategories"
                          :key="index"
                          v-if="isOpenProducts"
                        >
                          <div class="dd-tax__nav">
                            <div class="dd-tax__nav-link">
                              {{ category.name }}
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import IcoTodos from "../../assets/img/ico/ico-nav-bars.svg";
import IcoProductos from "../../assets/img/ico/ico-nav-carriage.svg";
import IcoMaternidad from "../../assets/img/ico/ico-nav-heart.svg";
import IcoListas from "../../assets/img/ico/ico-nav-featured.svg";

export default {
  name: "SubheaderBlog",
  data() {
    return {
      searchBar: false,
      showCategories: false,
      showCategoriesContent: false,
      isOpenMaternity: false,
      isOpenProducts: false,
    };
  },
  computed: {
    appQueryParam()
    {
      if(this.$store.state.cookies.sourceApp == "true") return "?source=app";
      return "";
    }
  },
  methods: {
    openModal() {
      document.body.classList.add("modal-open");
      this.showCategories = true;
    },
    closeModal() {
      this.showCategoriesContent = false;
      document.body.classList.remove("modal-open");
    },
  },
  created: function () {
    this.IcoTodos = IcoTodos;
    this.IcoListas = IcoListas;
    this.IcoProductos = IcoProductos;
    this.IcoMaternidad = IcoMaternidad;
    this.maternityCategories = [
      {
        slug: "babyshower",
        name: "Babyshower",
      },
      {
        slug: "crianza",
        name: "Crianza",
      },
      {
        slug: "embarazo",
        name: "Embarazo",
      },
      {
        slug: "preparandose-para-el-bebe",
        name: "Preparándose para el bebé",
      },
    ];
    this.productsCategories = [
      {
        slug: "a-jugar",
        name: "A jugar",
      },
      {
        slug: "alimentacion",
        name: "Alimentación",
      },
      {
        slug: "bano",
        name: "Baño",
      },
      {
        slug: "carritos",
        name: "Carritos",
      },
      {
        slug: "cunas",
        name: "Cunas",
      },
      {
        slug: "de-viaje",
        name: "De viaje",
      },
      {
        slug: "hogar",
        name: "Hogar",
      },
      {
        slug: "lactancia",
        name: "Lactancia",
      },
      {
        slug: "libros",
        name: "Libros",
      },
      {
        slug: "lo-que-mas-nos-gusta",
        name: "Lo que más nos gusta",
      },
      {
        slug: "panales",
        name: "Pañales",
      },
      {
        slug: "para-mama",
        name: "Para mamá",
      },
      {
        slug: "portabebes",
        name: "Portabebés",
      },
      {
        slug: "ropa",
        name: "Ropa",
      },
      {
        slug: "sillas-de-coche",
        name: "Sillas de coche",
      },
    ];
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.closeModal();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
};
</script>
