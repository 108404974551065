<template>
  <a class="thumb-post" :href="'/blog/posts/' + this.post.slug + appQueryParam">
    <div class="thumb-post__figure">
      <img :src="post.feature_image" :alt="post.feature_image_alt" />
      <!--<div class="skloader"></div>-->
    </div>
    <div class="thumb-post__content">
      <template v-if="!isRelated">
        <h4 class="thumb-post__title">
          {{ post.title }}
        </h4>
      </template>
      <template v-else>
        <div class="thumb-post__title">
          {{ post.title }}
        </div>
      </template>

      <div class="thumb-post__excerpt" v-if="showExcerpt">
        {{ $utils.truncate(post.custom_excerpt, 135) }}
      </div>
      <div class="thumb-post__tax">
        {{ $utils.capitalize(categoryName) }}
      </div>
    </div>
  </a>
</template>
<script>
export default {
  name: "BlogPostThumb",
  props: {
    showExcerpt: Boolean,
    isRelated: Boolean,
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    categoryName() {
      return this.post.primary_tag?.name;
    },
    categorySlug() {
      return this.post.primary_tag?.slug;
    },
    appQueryParam() {
      if (this.$store.state.cookies.sourceApp == "true") return "?source=app";
      return "";
    },
  },
};
</script>
<style lang=""></style>
