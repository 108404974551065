<template>
  <div class="cta-bubles">
    <div class="cta-bubles__content">
      <div class="cta-bubles__title">
        Se acerca ese día tan esperado... <br />
        ¿Ya lo tienes todo a punto?
      </div>
      <router-link
        to="/signup"
        class="button button--accent"
        alt="Crea tu lista"
        @click="browse"
        >Empieza tu lista</router-link
      >
      <!-- /signup -->
    </div>
    <div class="cta-bubles__cover">
      <img src="../../assets/img/covers/cover-cta-icons.svg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "CtaPost",
  methods: {
    browse() {
      this.$router.push({
        name: "BlogCategory",
        params: { slug: "listas-de-ejemplo" },
      });
    },
  },
};
</script>
