<template>
  <div>
    <SubheaderBlog />
    <main class="pad-3 bg-blog">
      <div class="bb-container bb-container--blog">
        <article class="post mar-b-4">
          <h1 class="post__h1">
            {{ post.title }}
          </h1>
          <div class="post__meta">
            <time class="post__date">{{ published_date }}</time>

            <div class="post__meta-separa">·</div>

            <div class="post__tags">
              <a
                :href="'/blog/categorias/' + category.slug + appQueryParam"
                :key="category.id"
                v-for="(category, index) in categories"
              >{{ category.name }}
                <span v-if="!(index === categories.length - 1)"> | </span></a
              >
            </div>
          </div>
          <div class="post__thumbnail">
            <img :src="post.feature_image" :alt="post.feature_image_alt" loading="lazy" />
          </div>
          <div class="post__entry">
            <div class="post-entry" v-html="htmlContent" />
          </div>

          <div class="post__autor" v-show="true">
            <PostAutor :author="post.primary_author"></PostAutor>
          </div>

          <div class="post__share">
            <Share :post="post"></Share>
          </div>
        </article>
      </div>
      <div class="pad-t-2 mar-b-1 bb-container">
        <CtaPost></CtaPost>
      </div>
    </main>

    <aside
      class="bg-grey-6 pad-3"
      v-if="relatedPostsAreLoaded && relatedPosts.length > 0"
    >
      <div class="bb-container">
        <h2 class="header-3 mar-b-2">Listado de posts relacionados</h2>
        <div class="g-post">
          <div class="g-post__items g-post__items--related">
            <BlogPostThumb
              v-for="relatedPost in relatedPosts"
              :key="relatedPost.id"
              :post="relatedPost"
              :isRelated="true"
            />
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>
<script>
import SubheaderBlog from "./SubheaderBlog.vue";
import BlogPostThumb from "./components/BlogPostThumb.vue";
import CtaPost from "../cta/CtaPost.vue";
import Share from "./components/Share.vue";
import { mapGetters } from "vuex";
import GhostContentAPI from "@tryghost/content-api";
import PostAutor from "./components/PostAutor";

export default {
  name: "BlogArticle",
  components: {
    SubheaderBlog,
    BlogPostThumb,
    CtaPost,
    Share,
    PostAutor
  },
  metaInfo() {
    return {
      title: this.post.title + " | HelloBB",
      meta: [
        { name: "description", content: this.post.meta_description },
        { name: "referrer", content: "no-referrer-when-downgrade" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: this.post.title },
        { name: "twitter:description", content: this.post.meta_description },
        { name: "twitter:image", content: "https://www.hellobb.net/hbb-card.jpg" },
        { name: "twitter:url", content: this.currentUrl() },
        { name: "twitter:label1", content: "Written by" },
        { name: "twitter:data1", content: "HelloBB" },
        { name: "twitter:label2", content: "HelloBB" },
        { name: "twitter:data2", content: this.post.primary_tag?.name },

        { property: "og:title", content: this.post.title },
        { property: "og:site_name", content: "HelloBB" },
        { property: "og:type", content: "article" },
        { property: "og:image", content: this.post.feature_image },
        //{ property: "og:image:width", content: "1280" },
        //{ property: "og:image:height", content: "775" },
        { property: "og:description", content: this.post.meta_description },
        { property: "og:url", content: this.currentUrl() },
        { property: "article:published_time", content: this.post.published_at },
        { property: "article:modified_time", content: this.post.updated_at },
        { property: "article:tag", content: this.post.primary_tag?.name },
        {
          property: "article:publisher",
          content: "https://www.facebook.com/holahelloBB"
        }
      ],
      link: [{ rel: "canonical", href: this.currentUrl() }],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Article",
            name: this.post.title,
            url: this.currentUrl(),
            image: this.post.feature_image,
            publisher: {
              "@type": "Organization",
              name: "HelloBB"
            }
          }
        },
        // Add Klaviyo script here
        // {
        //   src: "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Y8XDtP",
        //   async: true,
        //   type: "text/javascript",
        // },
      ]
    };
  },
  data() {
    return {
      relatedPosts: [],
      relatedPostsAreLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      post: "post",
      isAuthenticated: "isAuthenticated"
    }),
    category() {
      return this.post.primary_tag?.name;
    },
    categories() {
      return this.post.tags;
    },
    published_date() {
      return this.post.published_at ? this.$utils.formatDate(this.post.published_at) : "";
    },
    appQueryParam() {
      if (this.$store.state.cookies.sourceApp == "true") return "?source=app";
      return "";
    },
    htmlContent() {
      return this.post.html.replace("_blank", "_self");
    }
  },
  async serverPrefetch() {
    return this.getPost();
  },
  mounted() {
    this.getPost();
    const api = new GhostContentAPI({
      url: process.env.BLOG_URL,
      key: process.env.BLOG_API_KEY,
      version: process.env.BLOG_API_VERSION
    });
    this.loadRelatedPosts(api);
    if(!this.isAuthenticated) this.loadExternalScript();
  },
  methods: {
    getPost() {
      return this.$store.dispatch("getPost", this.$route.params.slug);
    },
    currentUrl() {
      return (
        process.env.APP_URL.substring(0, process.env.APP_URL.length - 1) +
        this.$route.path
      );
    },
    async loadRelatedPosts(api) {
      try {
        const filter = "tag:" + this.post.primary_tag?.slug;
        this.relatedPosts = await api.posts.browse({
          filter: filter,
          limit: 4,
          include: "tags"
        });
      } catch (error) {
        console.log(error);
      }
      this.relatedPostsAreLoaded = true;
    },
    loadExternalScript() {
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Y8XDtP';
      scriptElement.async = true;
      scriptElement.onload = () => console.log('Script loaded successfully.');
      document.body.appendChild(scriptElement);
    }
  }
};
</script>
