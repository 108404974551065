<template>
  <aside class="post-autor">
    <div class="post-autor__picture">
      <img :src="author.profile_image" :alt="author.name" />
    </div>
    <div class="post-autor__content">
      <div class="post-autor__name">{{ author.name }}</div>
      <div class="post-autor__summary">{{ author.bio }}</div>
      <div class="post-autor__actions">
        <router-link to="../autor/antonella" class="link link--underline link--bold">Más sobre el autor</router-link>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "PostAutor",
  props: {
    author: {
      type: Object,
      default: () => ({
        name: "",
        profile_image: "",
        bio: "",
        url: ""
      }),
    },
  },
};
</script>

<style lang="scss" scoped></style>